import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplatePDF(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let url = "";
  let noborder = false;
  let fullwidth = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "url") {
      url = decodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    }
  });

  h = (w * 297) / 210;

  return (
    <div
      className={`w-full flex flex-col prose justify-center ${
        noborder ? "" : " border border-gray-300 shadow"
      } bg-white overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        minWidth: fullwidth ? "100%" : `${w}px`,
        maxWidth: fullwidth ? "100%" : `${w}px`,
        height: `${h}px`,
        maxHeight: `${h}px`,
        minHeight: `${h}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        overflow: "hidden",
      }}
    >
      {/* <iframe
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0&frameborder=0&view=FitH&pagemode=none&page=1`}
        frameborder="0"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          transform: `scale(1.022)`,
        }}
      ></iframe> */}
      {/* <embed
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0&frameborder=0&view=FitH&pagemode=none&page=1`}
        type="application/pdf"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          transform: `scale(1.022)`,
        }}
      /> */}
      <object
        data={`${url}#toolbar=0&navpanes=0&scrollbar=0&frameborder=0&view=FitH&pagemode=none&page=1`}
        type="application/pdf"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          transform: `scale(1.022)`,
        }}
      />
    </div>
  );
}

export default TemplatePDF;
