export function stringToHex(string) {
  var hex = "";
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i).toString(16);
    if (code.length === 1) {
      code = "0" + code;
    }
    hex += code;
  }
  return hex;
}

export function myDecodeURIComponent(string) {
  try {
    return decodeURIComponent(string);
  } catch (e) {
    return "";
  }
}

export function hexToString(hex) {
  var string = "";
  for (var i = 0; i < hex.length; i += 2) {
    string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return string;
}

export function getNow() {
  return window.SERVER_TIME
    ? window.SERVER_TIME + Date.now() - window.BROWSER_TIME
    : Date.now();
}

export function getNowId(now) {
  now = now || getNow();
  const id = stringToHex("" + now);
  return id;
}

export function convertDateToMMMDDYYY(date) {
  date = new Date(date);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function getLoginToken() {
  // get token and and maxAge
  // if maxAge is good, return token
  // if not, delete token from localstorage

  const token = localStorage.getItem(
    process.env.REACT_APP_SD_UUID_KEY || "sd_uuid"
  );
  const maxAge = localStorage.getItem(
    process.env.REACT_APP_SD_UUID_MAX_AGE_KEY || "sd_uuid_max_age"
  );
  if (token && maxAge) {
    const now = getNow();
    if (now < maxAge) {
      return token;
    } else {
      localStorage.removeItem(process.env.REACT_APP_SD_UUID_KEY || "sd_uuid");
      localStorage.removeItem(
        process.env.REACT_APP_SD_UUID_MAX_AGE_KEY || "sd_uuid_max_age"
      );
    }
  }
}

export function deleteLoginToken() {
  localStorage.removeItem(process.env.REACT_APP_SD_UUID_KEY || "sd_uuid");
  localStorage.removeItem(
    process.env.REACT_APP_SD_UUID_MAX_AGE_KEY || "sd_uuid_max_age"
  );
}

export function setLoginToken(token, maxAge) {
  localStorage.setItem(process.env.REACT_APP_SD_UUID_KEY || "sd_uuid", token);
  localStorage.setItem(
    process.env.REACT_APP_SD_UUID_MAX_AGE_KEY || "sd_uuid_max_age",
    maxAge + getNow()
  );
}
