import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateListTimeline(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let list = [];
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "list") {
      list.push(myDecodeURIComponent(value));
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    }
  });

  list = list.filter((x) => x);

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-1/2 flex-1 ${
          invert ? "bg-blue-800" : "bg-gray-100"
        } pr-20 pl-16 pt-10 pb-28 relative flex flex-col justify-center`}
      >
        <h1
          className={`text-2xl -pl-2 font-black ${
            invert ? "text-white" : "text-blue-800"
          } leading-none`}
        >
          {heading}
        </h1>

        <div className="flex flex-col space-y-10 mt-6 relative justify-center">
          <div
            className={`absolute top-0 -bottom-10 w-1 z-10 border-l border-dashed ${
              invert ? "bg-blue-800 border-white" : "bg-gray-100 border-black"
            }`}
            style={{
              left: "11.5px",
            }}
          ></div>
          {list.map((item, index) => {
            return (
              <div className="flex z-20 relative">
                <div
                  className={`absolute -top-2 -right-6 -left-4 -bottom-2 rounded-full z-10 ${
                    invert ? "bg-blue-600" : "bg-blue-100"
                  }`}
                ></div>
                <div className="mr-4 z-20 flex flex-col justify-center">
                  <span
                    className={`leading-none text-2xl ${
                      invert ? "text-white" : "text-blue-800"
                    }`}
                  >
                    <i className="ri-checkbox-blank-circle-fill"></i>
                  </span>
                </div>
                <div
                  className={`text-lg z-20 font-bold leading-tight ${
                    invert ? "text-white" : "text-black"
                  }`}
                >
                  <span className="leading-none">{item}</span>
                </div>
              </div>
            );
          })}
        </div>

        <img
          className="absolute bottom-2 right-5"
          style={{
            height: "50px",
            objectFit: "contain",
            width: "120px",
            zIndex: 10,
            objectPosition: "center",
            filter: invert ? "brightness(0) invert(1)" : "none",
            opacity: 0.85,
          }}
          src={logo}
        />
      </div>
    </div>
  );
}

export default TemplateListTimeline;
