import React, { useEffect, useState } from "react";
import useCookie from "../hooks/useCookie";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS } from "../literals";
import useLocalStorage from "../hooks/useLocalStorage";

function Header(props) {
  const [token, setToken] = useCookie(
    process.env.REACT_APP_SD_UUID_KEY || "sd_uuid",
    ""
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  if (token) {
    return (
      <>
        <div className="w-full sidebar-toolkit flex space-x-2">
          <button
            className={`h-full px-4 flex items-center hover:font-extrabold hover:text-blue-500 ${
              props.blocksPage ? "text-blue-500" : ""
            }`}
            onClick={() => {
              setSidebarIsOpen(false);
              navigate("/blocks");
            }}
          >
            <i className={`ri-1x ri-mic-fill mr-1`}></i>
            <span className="font-extrabold">Blocks</span>
          </button>
          <button
            className={`h-full px-4 flex items-center hover:font-extrabold hover:text-blue-500 ${
              props.settingsPage ? "text-blue-500" : ""
            }`}
            onClick={() => {
              setSidebarIsOpen(false);
              navigate("/settings");
            }}
          >
            <i className={`ri-1x ri-settings-4-line mr-1`}></i>
            <span className="font-extrabold">Settings</span>
          </button>
        </div>
      </>
    );
  }

  return null;
}

export default Header;
