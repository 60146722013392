import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateMockupSimpleTwoSides(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "Sample heading";
  let background = "https://blocks-images-prod.btw.so/yellow-pencils-4-3.jpg";
  let image = "https://blocks-images-prod.btw.so/sample-resume.webp";
  let image2 = "https://blocks-images-prod.btw.so/sample-resume.webp";
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;
  let a4 = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "a4") {
      a4 = true;
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "background") {
      background = myDecodeURIComponent(value);
    } else if (key === "image") {
      image = myDecodeURIComponent(value);
    } else if (key === "image2") {
      image2 = myDecodeURIComponent(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "invert") {
      invert = true;
    }
  });

  const aspect = 4 / 3;
  const height = w / aspect;

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: `${w}px`,
        height: `${height}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={background}
        />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="flex flex-col h-full w-full items-center justify-center space-y-4">
            <div className="px-5 py-3 ">
              <h1 className="text-black text-3xl font-black leading-none text-center">
                {heading}
              </h1>
            </div>
            <div className="flex max-h-full space-x-9">
              <div className="">
                <img
                  style={{
                    height: `calc(${height}px - 7rem)`,
                    objectFit: "cover",
                    margin: "auto",
                    objectPosition: "top",
                  }}
                  src={image2}
                  className="max-h-full shadow-md"
                />
              </div>
              <div className="">
                <img
                  style={{
                    height: `calc(${height}px - 7rem)`,
                    objectFit: "cover",
                    margin: "auto",
                    objectPosition: "top",
                  }}
                  src={image}
                  className="max-h-full shadow-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateMockupSimpleTwoSides;
