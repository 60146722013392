import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams } from "react-router-dom";
import { Switch } from "@headlessui/react";
import AppWrapper from "./AppWraper";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "../components/Uppy";
import {
  generateAndUploadAudio,
  generateAndUploadTranscript,
  getJSONContentFromTranscriptUrl,
  generateYTScriptFromPrompt,
  generateImagePromptsFromScript,
  generateImageFromPrompt,
} from "../components/utils";
import LoadingSpinner from "../components/LoadingSpinner";

function Blocks(props) {
  const navigate = useNavigate();
  const [token, setToken] = useCookie(
    process.env.REACT_APP_SD_UUID_KEY || "sd_uuid",
    ""
  );
  const dispatch = useDispatch();

  const proUser = props.proUser;
  const adminUser = props.adminUser;

  const blocksState = useAppSelector(selectBlocks);

  const { changed } = useTreeChanges(blocksState);
  const { id } = useParams();

  if (token) {
    return <AppWrapper {...props} blocksPage={true}></AppWrapper>;
  }

  return null;
}

export default Blocks;
