import {
  all,
  delay,
  put,
  takeLatest,
  call,
  takeEvery,
} from "redux-saga/effects";

import toast from "react-hot-toast";

import { ActionTypes } from "literals";

import {} from "actions";

import axios from "axios";
const axiosInstance = axios.create({
  timeout: 20000,
  withCredentials: true,
});
const axiosInstanceWithoutCredentials = axios.create({
  timeout: 20000,
});

import genFingerprint from "../fingerprint";
import { getLoginToken, deleteLoginToken, setLoginToken } from "../utils";

// import FingerprintJS from '@fingerprintjs/fingerprintjs';

async function getFingerPrint() {
  return genFingerprint();
  // const fp = await FingerprintJS.load();

  // const { visitorId } = await fp.get();

  // return visitorId;
}

export default function* root() {
  yield all([]);
}
