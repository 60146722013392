import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";
const converter = new showdown.Converter();

function TemplateSimpleQnA(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "";
  let q = "";
  let a = "";
  let noborder = false;
  let fullwidth = false;
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "q") {
      q = myDecodeURIComponent(value);
    } else if (key === "a") {
      a = myDecodeURIComponent(value);
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    }
  });

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className={`w-1/2 flex-1 ${
          invert ? "bg-blue-800" : "bg-gray-100"
        } px-10 pt-12 pb-24 relative flex flex-col justify-center`}
      >
        <h1
          className={`text-3xl -mt-2 mb-8 font-black ${
            invert ? "text-white" : "text-blue-800"
          } leading-none`}
        >
          {heading}
        </h1>
        <div className="flex flex-col space-x-4">
          <div className="flex flex-col space-y-6">
            <div className="flex items-center">
              <div className="mr-6 w-12 shrink-0" style={{ paddingTop: "1px" }}>
                <span
                  className={`leading-none text-5xl font-black ${
                    invert ? "text-blue-300" : "text-blue-800"
                  }`}
                >
                  Q
                </span>
              </div>
              <div
                className={`text-lg font-bold leading-tight ${
                  invert ? "text-white" : "text-black"
                }`}
              >
                <span className="leading-none">{q}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="mr-6 w-12 shrink-0" style={{ paddingTop: "1px" }}>
                <span
                  className={`leading-none text-5xl font-black ${
                    invert ? "text-blue-300" : "text-blue-800"
                  }`}
                >
                  A
                </span>
              </div>
              <div
                className={`text-lg ${
                  invert ? "prose-invert" : "prose"
                } font-bold leading-tight ${
                  invert ? "text-white" : "text-black"
                }`}
              >
                <div
                  className="leading-tight space-y-2"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(a) }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute left-9 bottom-8"
          style={{
            height: "50px",
            objectFit: "contain",
            width: "120px",
            zIndex: 10,
            objectPosition: "center",
            filter: invert ? "brightness(0) invert(1)" : "none",
            opacity: 0.85,
          }}
          src={logo}
        />
      </div>
    </div>
  );
}

export default TemplateSimpleQnA;
