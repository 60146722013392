import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { selectUser } from "selectors";
import useTreeChanges from "tree-changes-hook";
import { useAppSelector } from "modules/hooks";

import toast, { Toaster } from "react-hot-toast";

import { getUser } from "actions";
import Login from "./routes/Login";
import Dash from "./routes/Dash";
import Blocks from "./routes/Blocks";
import NotFound from "routes/NotFound";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import Settings from "./routes/Settings";
import Templates from "./containers/TemplatesContainer";

function Root() {
  const dispatch = useDispatch();
  const userState = useAppSelector(selectUser);
  const { changed } = useTreeChanges(userState);

  const { user } = userState;
  const { isLoggedIn } = user;

  useEffect(() => {
    // get user details if it is the first time
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (changed("user.isLoggedIn", true) && isLoggedIn) {
      toast.success("Logged In!");
    }
  }, [dispatch, changed]);

  // THOUGHTS 102
  // For some reason, hard refresh on the urls is giving 404 if we use BrowserRouter in production

  // const Router = process.env.REACT_APP_ELECTRON ? HashRouter : BrowserRouter;
  const Router = HashRouter;

  Helmet.defaultProps.encodeSpecialCharacters = false;

  return (
    <Router>
      <div className="w-full h-full flex-grow flex flex-col">
        <Helmet
          defaultTitle={"SD"}
          defer={false}
          encodeSpecialCharacters={false}
          titleAttributes={{ itemprop: "name", lang: "en-en" }}
          titleTemplate={`%s | SD`}
        >
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Routes className="flex flex-grow">
          <Route
            className="flex flex-grow"
            element={
              <Templates
                userId={user && user.data ? user.data.id : null}
                name={user && user.data ? user.data.name : null}
                email={user && user.data ? user.data.email : null}
                proUser={user && user.data ? user.data.pro : null}
                adminUser={
                  user &&
                  user.data &&
                  user.data.pro &&
                  user.data.email &&
                  [
                    "siddhartha.gunti191@gmail.com",
                    "deepti.vchopra@gmail.com",
                    "sid@adaface.com",
                    "deepti@adaface.com",
                  ].includes(user.data.email)
                }
              />
            }
            path="/templates"
          />
          <Route
            className="flex flex-grow"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn} to="/login">
                <Blocks
                  userId={user && user.data ? user.data.id : null}
                  name={user && user.data ? user.data.name : null}
                  email={user && user.data ? user.data.email : null}
                  proUser={user && user.data ? user.data.pro : null}
                  adminUser={
                    user &&
                    user.data &&
                    user.data.pro &&
                    user.data.email &&
                    [
                      "siddhartha.gunti191@gmail.com",
                      "deepti.vchopra@gmail.com",
                      "sid@adaface.com",
                      "deepti@adaface.com",
                    ].includes(user.data.email)
                  }
                />
              </PrivateRoute>
            }
            path="/blocks"
          />

          <Route
            className="flex flex-grow"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn} to="/login">
                <Settings
                  userId={user && user.data ? user.data.id : null}
                  name={user && user.data ? user.data.name : null}
                  email={user && user.data ? user.data.email : null}
                />
              </PrivateRoute>
            }
            path="/settings"
          />
          <Route
            element={
              <PublicRoute isLoggedIn={isLoggedIn} to="/blocks">
                <Login />
              </PublicRoute>
            }
            path="/login"
          />
          <Route
            element={
              <PublicRoute isLoggedIn={isLoggedIn} to="/blocks">
                <Login />
              </PublicRoute>
            }
            path="/"
          />
          <Route element={<NotFound />} path="*" />
        </Routes>
        <Toaster />
      </div>
    </Router>
  );
}

export default Root;
