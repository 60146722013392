import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";

function TemplateTimeline(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  const paramsArray = [];
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "s") {
      paramsArray.push(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    }
  });

  return (
    <div
      className="w-full flex flex-col justify-center"
      style={{
        width: `${w}px`,
        height: `${h}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div
        className="flex flex-row px-32 py-40"
        style={{
          width: `${w}px`,
          padding: `12rem ${w / ((paramsArray.length / 2 + 1) * 2)}px 12rem ${
            w / ((paramsArray.length / 2 + 1) * 2)
          }px`,
        }}
      >
        {paramsArray.map((param, index) => {
          return [
            <div
              key={param}
              className="relative w-16 h-16 rounded-full flex flex-col items-center justify-center  font-black border-blue-500 text-2xl"
              style={{
                borderWidth: "6.5px",
              }}
            >
              <div>
                <span>{`${index + 1}.`}</span>
              </div>
              <div
                style={{
                  bottom: index % 2 === 0 ? "0" : "4rem",
                  top: index % 2 === 0 ? "3.6rem" : "-6.4rem",
                }}
                className={`absolute w-16 h-24 flex top-full items-center ${
                  index % 2 === 0 ? "flex-col" : "flex-col-reverse"
                }`}
              >
                <div className="bg-gray-300 h-40 w-0.5"></div>
                <div className="bg-gray-300 w-3 h-3 rounded-full grow shrink-0"></div>
              </div>
              <div
                className="font-black text-black text-xl absolute break-words text-center"
                style={{
                  ...(index % 2 === 0
                    ? {
                        top: "9.8rem",
                      }
                    : {
                        bottom: "9.9rem",
                      }),
                  lineHeight: "1",
                  width: `${w / (paramsArray.length / 2 + 1)}px`,
                  padding: "0 5px",
                }}
              >
                <span>{param}</span>
              </div>
            </div>,
            ...(index !== paramsArray.length - 1
              ? [
                  <div
                    className="flex-grow flex flex-col items-center justify-center"
                    key={`${param}2`}
                  >
                    <div
                      className="w-full h-1.5 mt-1  border-dashed border-gray-300"
                      style={{
                        borderTopWidth: "2.5px",
                      }}
                    ></div>
                  </div>,
                ]
              : []),
          ];
        })}
      </div>
    </div>
  );
}

export default TemplateTimeline;
