import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateStackedCards(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let headings = [];
  let paragraphs = [];
  let noborder = false;
  let fullwidth = false;
  let tag = "Default";
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "heading") {
      headings.push(myDecodeURIComponent(value));
    } else if (key === "paragraph") {
      paragraphs.push(myDecodeURIComponent(value));
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "invert") {
      invert = true;
    } else if (key === "tag") {
      tag = myDecodeURIComponent(value);
    }
  });

  headings = headings.filter((x) => x);
  paragraphs = paragraphs.filter((x) => x);

  // for each heading, if it is more than 36 characters, truncate it at 33 and add ...
  headings = headings.map((heading) => {
    if (heading.length > 36) {
      return heading.substring(0, 33) + "...";
    }
    return heading;
  });
  // for each paragraph, if it is more than 210 characters, truncate it at 197 and add ...
  paragraphs = paragraphs.map((paragraph) => {
    if (paragraph.length > 210) {
      return paragraph.substring(0, 197) + "...";
    }
    return paragraph;
  });

  const getCard = (heading, paragraph, tag, index, total) => {
    return (
      <div
        className={`w-1/2 flex aspect-square justify-center bg-gray-100 border-b rounded-lg shadow-xl ${
          noborder ? "" : ""
        } overflow-hidden absolute`}
        style={{
          left: `${(index / (total - 1)) * 48 + 1}%`,
          top: `${index * 64}px`,
          transformOrigin: "top left",
          zIndex: index,
        }}
      >
        <div className="flex flex-col w-full p-10">
          <h1 className="text-4xl text-blue-800 font-black">{heading}</h1>
          <div className="mt-4 mb-2 leading-none flex items-center">
            <span className="bg-blue-100 leading-6 text-blue-800 text-2xl font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
              {tag}
            </span>
          </div>
          <p className="text-2xl text-black font-medium">{paragraph}</p>
          <div className="shrink-0 grow-1 flex-grow"></div>
          <div className="flex justify-start">
            <img
              className="-mb-4"
              style={{
                height: "50px",
                objectFit: "contain",
                width: "120px",
                zIndex: 10,
                objectPosition: "center",
                filter: invert ? "brightness(0) invert(1)" : "none",
                opacity: 0.85,
              }}
              src={logo}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`block relative min-h-96 justify-center ${
        noborder ? "" : ""
      } bg-transparent`}
      style={{
        width: fullwidth ? "100%" : `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      {/* I want the first card to be on the extreme left and the last card to be on the extreme right. All the other cards should be equally spaced in between. z-index should be such that the last card is on top. vertically add top-32 space for each card*/}
      {headings.map((heading, index) => {
        return getCard(
          heading,
          index < paragraphs.length ? paragraphs[index] : "",
          tag,
          index,
          headings.length
        );
      })}
    </div>
  );
}

export default TemplateStackedCards;
