import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex, myDecodeURIComponent } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";
import showdown from "showdown";

function TemplateMockupIMac(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;
  let heading = "Sample heading";
  let background =
    "https://blocks-images-prod.btw.so/edit-everything-mac-4-3-bg.jpg";
  let applicationImage = "";
  let appContentImage = "";
  let logo =
    "https://res.cloudinary.com/adaface/image/upload/v1721824489/adaface-logo-transparent.png";
  let invert = false;
  let a4 = false;
  let noborder = false;
  let bullets = [];

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "html") {
      html = myDecodeURIComponent(value);
    } else if (key === "a4") {
      a4 = true;
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
      // } else if (key === "background") {
      //   background = myDecodeURIComponent(value);
    } else if (key === "applicationImage") {
      applicationImage = myDecodeURIComponent(value);
    } else if (key === "appContentImage") {
      appContentImage = myDecodeURIComponent(value);
    } else if (key === "logo") {
      logo = myDecodeURIComponent(value);
    } else if (key === "heading") {
      heading = myDecodeURIComponent(value);
    } else if (key === "noborder") {
      noborder = true;
    } else if (key === "fullwidth") {
      fullwidth = true;
    } else if (key === "invert") {
      invert = true;
    } else if (key === "bullet") {
      bullets.push(myDecodeURIComponent(value));
    }
  });

  const aspect = 4 / 3;
  const height = w / aspect;

  return (
    <div
      className={`flex min-h-96 justify-center ${
        noborder ? "" : ""
      } overflow-hidden`}
      style={{
        width: `${w}px`,
        height: `${height}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={background}
        />
        <div
          style={{
            left: `${(200 * w) / 800}px`,
            top: `${(25 * h) / 600}px`,
            width: `${(402 * w) / 800}px`,
            height: `${(210 * h) / 600}px`,
            overflow: "hidden",
            transform: "perspective(1000px) rotateX(1deg)",
          }}
          className="absolute"
        >
          <img
            src={applicationImage}
            style={{
              objectPosition: "top left",
              width: "100%",
            }}
            className="absolute top-0 h-full object-cover"
          />

          <img
            src={appContentImage}
            className="absolute"
            style={{
              width: `${(322 * w) / 800}px`,
              top: `${(50 * h) / 600}px`,
              left: `${(40 * w) / 800}px`,
            }}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 w-full flex px-10 py-5"
        style={{
          top: `${(465 * h) / 600}px`,
        }}
      >
        <div className="text-white text-3xl font-black leading-none text-center">
          {heading}
        </div>
        <div className="flex flex-col  gap-2 ml-20">
          {bullets.map((bullet) => (
            <div className="text-white text-xl font-black leading-none">
              {`• ${bullet}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TemplateMockupIMac;
