import React, { useEffect, useState, useRef } from "react";
import useCookie from "../hooks/useCookie";
import { selectNotes, selectNoteActions } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import AppWrapper from "./AppWraper";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";

function Dash(props) {
  const navigate = useNavigate();
  const [token, setToken] = useCookie(
    process.env.REACT_APP_SD_UUID_KEY || "sd_uuid",
    ""
  );
  // const { changed: notesStateChanged } = useTreeChanges(notesState);
  const dispatch = useDispatch();

  const proUser = props.proUser;
  const adminUser = props.adminUser;

  if (token) {
    return (
      <AppWrapper {...props}>
        {token && props.userId ? (
          <div className="flex flex-grow flex-col max-h-screen">
            <div className="mb-2 px-2 border-b-2 border-gray-200 py-2 flex">
              <div className="flex flex-grow"></div>
              <div className="flex space-x-5 ml-5"></div>
            </div>
            <div className="flex h-full overflow-hidden">
              <div
                className="mt-2 w-80 flex-grow shrink-0 hidden sm:block"
                style={{
                  whiteSpace: "wrap",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  maxWidth: "20rem",
                }}
              ></div>
            </div>
          </div>
        ) : null}
      </AppWrapper>
    );
  }

  return null;
}

export default Dash;
