import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import axios from "axios";

const DEEPGRAM_API_KEY = "bc01203827ad1c70b8c069a208dd5261b9c49032";
const OPEN_AI_API_KEY =
  "sk-proj-tACMcjcKBjhUtZHgm5HpT3BlbkFJVKQ4Lo28SXUPNBFtTyUR";
const STABILITY_AI_API_KEY =
  "sk-AYGcqQuZ5Scfx6bhiXKpgZgr1KFvzqNnQ4nlIBOYXZnHTiJF";

export const uploadAudioBlob = async (
  audioBlob,
  onUploadSuccess,
  onUploadError,
  name
) => {
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: 10000000, // Maximum file size in bytes
      allowedFileTypes: ["audio/*"],
    },
    autoProceed: true, // Automatically proceed with the upload once file is added
  });

  uppy.use(AwsS3, {
    companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL, // URL to your companion server
    timeout: ms("1 minute"),
    limit: 1,
    acl: "public-read",
  });

  uppy.on("complete", (result) => {
    if (result.successful.length > 0) {
      const urls = result.successful.map((file) => file.uploadURL);
      onUploadSuccess(urls);
    } else if (result.failed.length > 0) {
      onUploadError("Upload failed.");
    }
    uppy.close();
  });

  // Add the audioBlob as a file to Uppy
  uppy.addFile({
    name: name || "audio-file", // You might want to generate a unique name
    type: audioBlob.type,
    data: audioBlob,
  });

  // Handle errors
  uppy.on("error", (error) => {
    console.error("Upload error:", error);
    onUploadError(error);
    uppy.close();
  });
};

const uploadFileToS3 = async (file, fileName, fileType) => {
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: 10000000, // adjust this as necessary
      allowedFileTypes: [
        "audio/*",
        "video/*",
        "image/*",
        "text/*",
        "application/json",
      ],
    },
    autoProceed: true,
  });

  uppy.use(AwsS3, {
    companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
    timeout: ms("1 minute"),
    limit: 1,
    acl: "public-read",
  });

  return new Promise((resolve, reject) => {
    uppy.addFile({
      name: fileName,
      type: file.type,
      data: file,
    });

    uppy.on("complete", (result) => {
      if (result.successful.length > 0) {
        resolve(
          result.successful[0].uploadURL
            .split(
              "https://nyc3.digitaloceanspaces.com/nyc3.digitaloceanspaces.com"
            )
            .join("https://nyc3.digitaloceanspaces.com")
            .split("%2F")
            .join("/")
        );
      } else {
        reject("Upload failed");
      }
      uppy.close();
    });

    uppy.on("error", (error) => {
      console.error("Upload error:", error);
      reject(error);
      uppy.close();
    });
  });
};

export const generateAndUploadAudio = async (text, id) => {
  try {
    const audioResponse = await fetch(
      "https://api.deepgram.com/v1/speak?model=aura-athena-en",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${DEEPGRAM_API_KEY}`,
        },
        body: JSON.stringify({ text }),
      }
    );

    const audioBlob = await audioResponse.blob();
    const audioUrl = await uploadFileToS3(
      audioBlob,
      `uppy-tts-audio-${id}`,
      "audio/mp3"
    );
    return { success: true, audioUrl };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err.message,
    };
  }
};

export const generateAndUploadTranscript = async (audioUrl, id) => {
  console.log("Going on", audioUrl, id);
  try {
    const timestamps = await generateTimestampsUsingDeepgram(audioUrl);
    const jsonBlob = new Blob([JSON.stringify(timestamps)], {
      type: "application/json",
    });
    const transcriptUrl = await uploadFileToS3(
      jsonBlob,
      `uppy-tts-transcript-${id}.json`,
      "application/json"
    );
    return { success: true, transcriptUrl, timestamps };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};

export const getJSONContentFromTranscriptUrl = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Failed to fetch transcript");
    const jsonData = await response.json();
    return { success: true, data: jsonData };
  } catch (error) {
    console.error("Error fetching JSON content:", error);
    return { success: false, error };
  }
};

export const generateTimestampsUsingDeepgram = async (audioUrl) => {
  try {
    console.log("DG1");
    // Set up the request options with headers and the request body
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Token ${DEEPGRAM_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: audioUrl, // URL of the audio file
        smart_format: true,
        language: "en",
        model: "nova-2",
      }),
    };

    console.log("DG2");
    // Send the request to the Deepgram API
    const response = await fetch(
      "https://api.deepgram.com/v1/listen",
      requestOptions
    );
    if (!response.ok)
      throw new Error("Failed to transcribe audio with Deepgram");

    console.log("DG3");
    // Parse the JSON response
    const transcriptionData = await response.json();

    console.log("DG4");
    // Extract timestamps and text from the response
    const timestamps =
      transcriptionData.results.channels[0].alternatives[0].words.map(
        (word) => ({
          start: word.start,
          end: word.end,
          text: word.word,
        })
      );

    console.log("DG5", timestamps);
    return timestamps;
  } catch (error) {
    console.error("Error with Deepgram transcription:", error);
    throw error;
  }
};

const generateTimestamps = async (audioUrl) => {
  try {
    console.log("AA2");
    // Download the audio file
    const audioResponse = await fetch(audioUrl);
    if (!audioResponse.ok) throw new Error("Failed to download audio");
    console.log("AA3");

    const audioBuffer = await audioResponse.arrayBuffer();
    const audioBlob = new Blob([audioBuffer], { type: "audio/mp3" }); // Convert ArrayBuffer to Blob

    console.log("AA5");

    const form = new FormData();
    form.append("model", "whisper-1");
    form.append("response_format", "text");
    form.append("language", "en");
    form.append("temperature", 0.1);
    form.append("file", audioBlob, {
      filename: "audio.mp3",
      contentType: "audio/mp3",
    });

    // Making the API call to OpenAI's Whisper endpoint
    const transcriptionResponse = await fetch(
      "https://api.openai.com/v1/audio/transcriptions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPEN_AI_API_KEY}`,
          // ...form.getHeaders(),
        },
        body: form,
      }
    );

    console.log("AA6");

    if (!transcriptionResponse.ok)
      throw new Error("Failed to transcribe audio");

    const transcriptionData = await transcriptionResponse.json();

    console.log("AA7");

    // Extract timestamps and text from the response
    const timestamps = transcriptionData["transcriptions"].map((item) => ({
      start: item.start,
      end: item.end,
      text: item.text,
    }));

    console.log("AA8", timestamps);

    return timestamps;
  } catch (error) {
    console.error("Error generating timestamps:", error);
    throw error;
  }
};

export const generateYTScriptFromPrompt = async (prompt) => {
  const finalPrompt = `
You are a YouTube script writer. You have been given the following prompt to write a script for a YouTube video. Write a script for a YouTube video based on the following prompt:
${prompt}

Rules: 
- The script should be engaging and informative
- The script should be maximum 100 words
- The script should be written in English

Output: 
JSON
key: script
value: Your script
  `;

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${OPEN_AI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo-16k",
        max_tokens: 300,
        temperature: 0.5,
        frequency_penalty: 0,
        presence_penalty: 0,
        top_p: 1,
        messages: [
          { role: "system", content: "You are a Youtube script writer" },
          {
            role: "user",
            content: finalPrompt,
          },
        ],
      }),
    });

    const data = await response.json();

    console.log("YT Script", data);

    let script = data.choices[0].message.content;

    try {
      script = JSON.parse(script);

      return { success: true, script: script.script };
    } catch (error) {
      console.error("Error parsing script:", error);
      return { success: false, error: error.message };
    }
  } catch (error) {
    console.error("Error generating script:", error);
    return { success: false, error: error.message };
  }
};

export const generateImagePromptsFromScript = async (script) => {
  const finalPrompt = `
You are an image creator who creates images for a Youtube Reel script using image generation tools like Dall-E. You have been given the following script to create 10 image prompts based on it.
${script}

Rules:
- The images should be engaging and informative
- The images should be related to the script
- The images should be unique and creative
- Each prompt you generate will be used to generate the actual image
- Each image would be showed along side the script as the narrator speaks the script. So the images should be in sync with the script.

Output:
JSON
key: images
value: List of 10 image prompts
  `;

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${OPEN_AI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo-16k",
        max_tokens: 500,
        temperature: 0.5,
        frequency_penalty: 0,
        presence_penalty: 0,
        top_p: 1,
        messages: [
          {
            role: "system",
            content: "You are an image creator",
          },
          {
            role: "user",
            content: finalPrompt,
          },
        ],
      }),
    });

    const data = await response.json();

    console.log("Image Prompts", data);

    let images = data.choices[0].message.content;

    try {
      images = JSON.parse(images);

      return { success: true, images: images.images };
    } catch (error) {
      console.error("Error parsing images:", error);
      return { success: false, error: error.message };
    }
  } catch (error) {
    console.error("Error generating images:", error);
    return { success: false, error: error.message };
  }
};

export const generateImageFromPrompt = async (prompt) => {
  const headers = {
    Authorization: `Bearer ${STABILITY_AI_API_KEY}`,
    Accept: "image/*",
  };

  const formData = new FormData();
  formData.append("prompt", prompt);
  formData.append("output_format", "webp");
  formData.append("aspect_ratio", "9:16");

  try {
    const response = await fetch(
      "https://api.stability.ai/v2beta/stable-image/generate/core",
      {
        method: "POST",
        headers: headers,
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const imageBlob = await response.blob();
    const imageUrl = await uploadFileToS3(
      imageBlob,
      `uppy-image-${Date.now()}`,
      "image/webp"
    );

    console.log("ADSADA", response, imageUrl);

    return {
      success: true,
      imageUrl,
    };
  } catch (error) {
    console.error("Error generating image:", error);
    return { success: false, error: error.message };
  }
};
