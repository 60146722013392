import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TemplateTimeline from "../components/TemplateTimeline";
import TemplateMockupSimpleSide from "../components/TemplateMockupSimpleSide";
import TemplateMockupSimpleTwoSides from "../components/TemplateMockupSimpleTwoSides";
import TemplateRadarSimple from "../components/TemplateRadarSimple";
import TemplateQnA from "../components/TemplateQnA";
import TemplateProse from "../components/TemplateProse";
import TemplateListUnsplash from "../components/TemplateListUnsplash";
import TemplateListSimple from "../components/TemplateListSimple";
import TemplateListTimeline from "../components/TemplateListTimeline";
import TemplateListSimpleSide from "../components/TemplateListSimpleSide";
import TemplateSimpleQnA from "../components/TemplateSimpleQnA";
import TemplateListHuge from "../components/TemplateListHuge";
import TemplateStackedCards from "../components/TemplateStackedCards";
import TemplateMockupIcons from "../components/TemplateMockupIcons";
import TemplateMockupIMac from "../components/TemplateMockupIMac";
import TemplateMockupIPad from "../components/TemplateMockupIPad";
import TemplatePDF from "../components/TemplatePDF";
import TemplateMockupMain from "../components/TemplateMockupMain";

function Templates(props) {
  const location = useLocation();

  let t = "timeline";

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  const paramsArray = [];

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "t") {
      t = value;
    }
  });

  if (t === "timeline") {
    return <TemplateTimeline {...props} />;
  } else if (t === "radar-simple") {
    return <TemplateRadarSimple {...props} />;
  } else if (t === "qna") {
    return <TemplateQnA {...props} />;
  } else if (t === "prose") {
    return <TemplateProse {...props} />;
  } else if (t === "list-unsplash") {
    return <TemplateListUnsplash {...props} />;
  } else if (t === "list-simple") {
    return <TemplateListSimple {...props} />;
  } else if (t === "list-timeline") {
    return <TemplateListTimeline {...props} />;
  } else if (t === "list-simple-side") {
    return <TemplateListSimpleSide {...props} />;
  } else if (t === "simple-qna") {
    return <TemplateSimpleQnA {...props} />;
  } else if (t === "list-huge") {
    return <TemplateListHuge {...props} />;
  } else if (t === "stacked-cards") {
    return <TemplateStackedCards {...props} />;
  } else if (t === "mockup-simple-side") {
    return <TemplateMockupSimpleSide {...props} />;
  } else if (t === "mockup-simple-two-sides") {
    return <TemplateMockupSimpleTwoSides {...props} />;
  } else if (t === "mockup-icons") {
    return <TemplateMockupIcons {...props} />;
  } else if (t === "mockup-imac") {
    return <TemplateMockupIMac {...props} />;
  } else if (t === "mockup-ipad") {
    return <TemplateMockupIPad {...props} />;
  } else if (t === "pdf") {
    return <TemplatePDF {...props} />;
  } else if (t === "mockup-main") {
    return <TemplateMockupMain {...props} />;
  }

  return null;
}

export default Templates;
