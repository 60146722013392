import React, { useEffect, useState, useRef } from "react";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import useCookie from "../hooks/useCookie";
import UppyComponent from "../components/Uppy";
import useLocalStorage from "../hooks/useLocalStorage";
import { selectUser, selectOtp } from "../selectors";
import { updateUser } from "../actions";
import useTreeChanges from "tree-changes-hook";
import { Switch } from "@headlessui/react";

import AppWrapper from "./AppWraper";

function SettingsContainer(props) {
  const dispatch = useDispatch();
  const userState = useAppSelector(selectUser);
  const { user } = userState;
  const { changed } = useTreeChanges(userState);
  const actions = useAppSelector(selectOtp);
  const [token, setToken] = useCookie(
    process.env.REACT_APP_SD_UUID_KEY || "sd_uuid",
    ""
  );

  const isUserPro = !!(user.data || {}).pro;
  const isAdmin =
    isUserPro &&
    [
      "deepti.vchopra@gmail.com",
      "siddhartha.gunti191@gmail.com",
      "sid@adaface.com",
      "deepti@adaface.com",
    ].includes((user.data || {}).email);
  const currentName = (user.data || {}).name || "";
  const currentPic = (user.data || {}).pic || "";
  const currentSettings = (user.data || {}).settings || {};

  const [showPicUploader, setShowPicUploader] = useState(false);

  const [name, setName] = useState(currentName);
  const [pic, setPic] = useState(currentPic);
  const [settings, setSettings] = useState({
    ...currentSettings,
  });

  useEffect(() => {
    if (changed("user.data.name")) {
      setName(user.data.name);
    }
  }, [user.data.name]);

  useEffect(() => {
    if (changed("user.data.pic")) {
      setPic(user.data.pic);
    }
  }, [user.data.picture]);

  const activeUpdateButton = name !== currentName || pic !== currentPic;

  return (
    <AppWrapper {...props} settingsPage={true}>
      {token && props.userId ? (
        <div className={`flex-grow p-4 flex flex-col overflow-y-scroll`}>
          <div className={`h-4 sm:hidden`}></div>
          <div className="mb-4 max-w-lg">
            <h2 className="font-extrabold text-lg mb-2">Profile</h2>
            <label className="block font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="mb-4 max-w-lg">
            <label className="block font-bold mb-2" htmlFor="pic">
              Picture
            </label>
            <div className="">
              <img
                src={pic || "https://dummyimage.com/300"}
                onClick={() => {
                  setShowPicUploader(true);
                }}
              />
              <div className="mt-2">
                <div
                  className={`w-full h-full backdrop-blur-sm bg-white/30 top-0 left-0 flex flex-col items-center justify-center ${
                    showPicUploader ? "absolute" : "absolute hidden"
                  }`}
                  onClick={() => {
                    setShowPicUploader(false);
                  }}
                >
                  <div
                    className=""
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <UppyComponent
                      maxNumberOfFiles={1}
                      allowedFileTypes={["image/*"]}
                      allowMultipleUploads={false}
                      onResults={(res) => {
                        let url =
                          res.urls && res.urls.length > 0 ? res.urls[0] : "";

                        if (url) {
                          if (process.env.REACT_APP_S3_ENDPOINT) {
                            url = url
                              .split(
                                `${process.env.REACT_APP_S3_ENDPOINT}/${process.env.REACT_APP_S3_ENDPOINT}`
                              )
                              .join(process.env.REACT_APP_S3_ENDPOINT);
                          }

                          setPic(url);
                        }

                        setShowPicUploader(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-2 items-center justify-between">
              <button
                className={`flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm ${
                  activeUpdateButton ? "" : "opacity-50 cursor-not-allowed"
                }`}
                type="button"
                onClick={() => {
                  if (user.status === STATUS.RUNNING) {
                    return;
                  }

                  if (!activeUpdateButton) {
                    return;
                  }

                  dispatch(
                    updateUser({
                      name,
                      pic,
                      settings: {
                        ...settings,
                      },
                    })
                  );
                }}
              >
                {user.status === STATUS.RUNNING ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-75"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-100"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
                    ></path>
                  </svg>
                ) : null}
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </AppWrapper>
  );
}

export default SettingsContainer;
