import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Text,
} from "recharts";

const CustomRadarTick = ({ payload, x, y, cx, cy, textAnchor, ...rest }) => {
  return ((payload.value || "").split(" ") || []).map((v, i) => (
    <Text
      {...rest}
      fill={"#363636"}
      verticalAnchor="middle"
      lineHeight="35"
      y={
        y +
        (((payload.value || "").split(" ") || []).length > 2 ? -20 : 0) +
        (textAnchor === "middle" && y < x
          ? -20
          : textAnchor === "middle" && y > x
          ? 20
          : 0)
      }
      x={x + (textAnchor === "start" ? 12 : textAnchor === "end" ? -12 : 0)}
      dy={i * 27}
      textAnchor={textAnchor}
    >
      {v}
    </Text>
  ));
};

const RadarChartH = ({ data, w, h }) => {
  const fillColors = ["#ffdd32", "#08b2e3"];
  const radarDataKeys = ["first", "second"];
  const radarData = data.map((x, i) => ({
    name: x,
    first: i === 0 ? 55 : 75 + Math.floor(Math.random() * 15),
    second: i == 0 ? 80 : 50 + Math.floor(Math.random() * 15),
  }));

  data.map((i, index) => {
    for (var j = 0; j < data.length; j++) {
      radarData[j] = {
        ...radarData[j],
        [`line${index}`]: index === j ? 100 : 0,
      };
    }
  });

  return (
    <ResponsiveContainer width={w} height={h}>
      <RadarChart outerRadius={160} data={radarData}>
        {/* <defs>
          <filter
            id="blurredRadar"
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
          >
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
          </filter>
        </defs> */}
        {/* <PolarGrid strokeOpacity={0.8} radialLines stroke="#000" /> */}
        <PolarAngleAxis
          dataKey={"name"}
          fontSize={22}
          lineHeight={25}
          fill="#000000"
          width={300}
          fontFamily="Satoshi"
          fontWeight={700}
          tick={CustomRadarTick}
        />
        {/* <PolarRadiusAxis angle={90} domain={[0, 100]} tick={false} /> */}
        {radarDataKeys.map((key, i) => [
          <Radar
            key={`blurred-${key}`}
            filter="url(#blurredRadar)"
            strokeWidth={3.5}
            name={""}
            dataKey={key}
            stroke={fillColors[i]}
            fill={fillColors[i]}
            legendType="circle"
            fillOpacity={0.6}
          />,
          <Radar
            key={key}
            strokeWidth={3.5}
            name={key}
            dataKey={key}
            stroke={fillColors[i]}
            fill={fillColors[i]}
            legendType="circle"
            fillOpacity={0.9}
            dot={({ cx, cy, value }) => (
              <circle cx={cx} cy={cy} r={4} fill={fillColors[i]} />
            )}
          />,
        ])}
        {data.map((val, i) => (
          <Radar
            key={`line${i}`}
            name={`line${i}`}
            dataKey={`line${i}`}
            stroke="#000"
            fill="#000"
            strokeWidth={2}
            fillOpacity={1}
            dot={false}
          />
        ))}
      </RadarChart>
    </ResponsiveContainer>
  );
};

function TemplateRadarSimple(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  const paramsArray = [];
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "s") {
      paramsArray.push(value);
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    }
  });

  return (
    <div
      className="w-full flex flex-col justify-center"
      style={{
        width: `${w}px`,
        height: `${h}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        padding: "0px",
      }}
    >
      <RadarChartH w={w} h={h} data={[...paramsArray]} />
    </div>
  );
}

export default TemplateRadarSimple;
