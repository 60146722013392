import React from "react";
import BlocksContainer from "../containers/BlocksContainer";

function Blocks(props) {
  return (
    <div className="w-full h-full flex-grow flex flex-col">
      <BlocksContainer {...props} />
    </div>
  );
}

export default Blocks;
