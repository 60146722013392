import { createReducer } from "@reduxjs/toolkit";

import { STATUS } from "literals";

import {} from "actions";

export const blocksState = {};

export default {
  blocks: createReducer(blocksState, (builder) => {}),
};
