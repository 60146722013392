import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  Component,
} from "react";
import useCookie from "../hooks/useCookie";
import { selectBlocks } from "../selectors";
import { useAppSelector } from "modules/hooks";
import useInterval from "beautiful-react-hooks/useInterval";
import { useDispatch } from "react-redux";
import { STATUS } from "../literals";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import useTreeChanges from "tree-changes-hook";
import toast from "react-hot-toast";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import SineWaves from "sine-waves";
import useSize from "../hooks/useSize";
import { getNow, stringToHex } from "../utils";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import UppyComponent from "./Uppy";
import LoadingSpinner from "./LoadingSpinner";

function TemplateQnA(props) {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(location.search);
  const qArray = [];
  const aArray = [];
  let w = window.innerWidth;
  let h = window.innerHeight;
  let scale = 1;

  // 'param' is the name of the query parameter used in the URL
  searchParams.forEach((value, key) => {
    if (key === "q") {
      qArray.push(decodeURIComponent(value));
    } else if (key === "a") {
      aArray.push(decodeURIComponent(value));
    } else if (key === "w") {
      w = Number(value);
    } else if (key === "h") {
      h = Number(value);
    } else if (key === "scale") {
      scale = Number(value);
    }
  });

  return (
    <div
      className="w-full flex min-h-96 justify-center border-4 rounded p-10 border-gray-400 overflow-hidden"
      style={{
        width: `${w}px`,
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      }}
    >
      <div className="w-3/5 space-y-4">
        {qArray.map((q, index) => {
          if (q.startsWith("https")) {
            if (q.endsWith(".mp3")) {
              return (
                <audio controls>
                  <source src={q} />
                </audio>
              );
            }

            return (
              <div className="text-xl">
                <img src={q} className="rounded" />
              </div>
            );
          }
          return (
            <div className="text-xl whitespace-pre-wrap">
              <span>{q}</span>
            </div>
          );
        })}
      </div>
      <div className="w-2/5 space-y-6 pl-10">
        {aArray.map((a, index) => {
          return (
            <div className="border-2 border-gray-400 px-4 py-2 rounded font-bold text-lg flex items-center">
              <span className="mr-2 flex flex-col justify-center text-gray-400">
                <i class="ri-checkbox-blank-circle-line"></i>
              </span>
              <span>{a}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TemplateQnA;
